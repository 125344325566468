import { AsymmetricCrypto } from '../crypto';

/**
 * Generate a user ID from an email
 * @param {string} email - user email
 * @returns {string} user id
 */
const generateUserId = (email) => {
  const publicKey = import.meta.env.VITE_CRYPTO_PUBLIC_KEY;
  return AsymmetricCrypto.encryptEmail(email, publicKey);
};

/**
 * Initialize Google Tag Manager
 */
export const initGTM = () => {
  const gtmId = import.meta.env.VITE_GTM_ID;
  if (gtmId) {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);
  }
};

/**
 * Push event to dataLayer
 * @param {object} event - The event to send
 */
const pushToDataLayer = (event) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

/**
 * Send a contact form submit event
 */
export const trackContactFormSubmit = (brandName, email) => {
  const userId = generateUserId(email);
  pushToDataLayer({
    event: 'contact_form_submit',
    userId,
    brandName,
  });
};

/**
 * Send a brand search event
 */
export const trackBrandSearch = (brandName, email) => {
  const userId = generateUserId(email);
  pushToDataLayer({
    event: 'brand_search',
    userId,
    brandName,
  });
};

/**
 * Send a checkout event
 */
export const trackCheckout = (brandName, email, stepIndex, direction) => {
  const userId = generateUserId(email);
  pushToDataLayer({
    event: 'checkout',
    userId,
    brandName,
    stepIndex,
    direction,
  });
};

/**
 * Send a brand registration event
 */
export const trackBrandRegistration = (brandName, email, transactionId) => {
  const userId = generateUserId(email);
  pushToDataLayer({
    event: 'brand_registration',
    userId,
    brandName,
    transactionId,
  });
};

/**
 * Send a brand registration completed event
 */
export const trackBrandRegistrationCompleted = () => {
  pushToDataLayer({
    event: 'brand_registration_completed',
  });
};

/**
 * Send a brand registration failed event
 */
export const trackBrandRegistrationFailed = (errorCode, orderId) => {
  pushToDataLayer({
    event: 'brand_registration_failed',
    errorCode,
    orderId,
  });
};

/**
 * Send a page view event
 * @param {string} path - The current page path
 * @param {string} [email] - Optional user email for tracking
 */
export const trackPageView = (path, email) => {
  const event = {
    event: 'page_view',
    page_path: path,
  };

  if (email) {
    event.userId = generateUserId(email);
  }

  pushToDataLayer(event);
};

/**
 * Send an exception event
 * @param {Error|string} error - The error object or message
 * @param {boolean} [fatal=false] - Whether the error was fatal
 */
export const trackException = (error, fatal = false) => {
  pushToDataLayer({
    event: 'exception',
    error_description: error instanceof Error ? error.message : error,
    error_fatal: fatal,
  });
};
