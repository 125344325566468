import forge from 'node-forge';

export class AsymmetricCrypto {
  /**
   * Genera un par de claves RSA
   * @returns {Object} Par de claves {publicKey, privateKey}
   */
  static generateKeyPair() {
    const rsa = forge.pki.rsa;
    const keypair = rsa.generateKeyPair({
      bits: 2048,
      workers: 2,
    });

    // Convertir a formato PEM
    const publicKey = forge.pki.publicKeyToPem(keypair.publicKey);
    const privateKey = forge.pki.privateKeyToPem(keypair.privateKey);

    return {
      publicKey,
      privateKey,
    };
  }

  /**
   * Encripta un email usando la clave pública
   * @param {string} email - Email a encriptar
   * @param {string} publicKeyPem - Clave pública en formato PEM
   * @returns {string} Email encriptado en base64
   */
  static encryptEmail(email, publicKeyPem) {
    try {
      // Convertir PEM a clave pública
      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

      // Encriptar
      const encrypted = publicKey.encrypt(email, 'RSA-OAEP');

      // Convertir a base64
      return forge.util.encode64(encrypted);
    } catch (error) {
      console.error('Error al encriptar:', error);
      throw error;
    }
  }

  /**
   * Desencripta un email usando la clave privada
   * @param {string} encryptedEmail - Email encriptado en base64
   * @param {string} privateKeyPem - Clave privada en formato PEM
   * @returns {string} Email original
   */
  static decryptEmail(encryptedEmail, privateKeyPem) {
    try {
      // Convertir PEM a clave privada
      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);

      // Convertir de base64 a bytes
      const encrypted = forge.util.decode64(encryptedEmail);

      // Desencriptar
      return privateKey.decrypt(encrypted, 'RSA-OAEP');
    } catch (error) {
      console.error('Error al desencriptar:', error);
      throw error;
    }
  }
}
